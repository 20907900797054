import Nidhi from '../../../images/Nidhi.jpg';
import Vaibhav from '../../../images/Vaibhav.jpg';
import Athashree from '../../../images/Athashree.jpg';
import Pravin from '../../../images/Pravin.jpg';
import Raunak from '../../../images/Raunak.jpg';
import Anirudha from '../../../images/Anirudha.jpg';
import Shreenath from '../../../images/Shreenath.jpg';
import Aditi from '../../../images/Aditi.jpg';
import Alok from '../../../images/Alok.jpg';
import AMOL from '../../../images/AMOL.jpg';
import Ayush from '../../../images/Ayush.jpg';
import Bhagyashri from '../../../images/Bhagyashri.jpg';
import Pranjal from '../../../images/Pranjal.jpg';
import PranjalJ from '../../../images/PranjalJ.jpg';
import Ruie from '../../../images/Ruie.jpg';
import Shraddha from '../../../images/Shraddha.jpg';
import Vinayak from '../../../images/Vinayak.jpg';
import Shrishail from '../../../images/Shrishail.JPG';
import dushant from "../../../images/Dushant_Technical cheif officer.jpg"
import pratiksha from "../../../images/Pratiksha_Chief Officer Technical.jpg"
import bhavika from "../../../images/IMG-20211226-WA0032 - Bhavika Purav.jpg"
import apurv from "../../../images/IMG_20221020_201730 - APURV SONAWANE.jpg"
import amey from "../../../images/Picsart_22-10-20_19-46-56-806 - Amey Pashte.jpg"
import jagruti from '../../../images/jagruti.jpg'
import sir from '../../../images/sir.jpg'
import an from '../../../images/anjalimaam.jpg';
import ma from '../../../images/mansimaam.jpg'
import Tejashree from '../../../images/Tejashree.jpg';
import Saurabh from '../../../images/Saurabh.jpg';
import Kalyani from '../../../images/Kalyani.jpg';
import YUVRAJ from '../../../images/YUVRAJ.jpg';
import Kashyap from '../../../images/Kashyap.jpg';
import KRISHNA from '../../../images/KRISHNA.jpeg';
import Aditya from '../../../images/Aditya Kunjir.jpg';
import Divya from '../../../images/Divya.png';
import Yash from '../../../images/Yash.jpg';
import Abhay from '../../../images/Abhay.JPG';
import Prahesh from '../../../images/Prahesh.jpg';
import DivyaJ from '../../../images/Divya.jpg';
import Lenyadri from '../../../images/Lenyadri.jpeg';


// const f={
//     Tejashree,
//     Saurabh,
//     Kalyani,
//     YUVRAJ,
//     Kashyap,
//     KRISHNA,
//     Aditya,
//     Divya,
//     Yash,
//     Abhay,
//     Prahesh,
//     DivyaJ,
//     Lenyadri
//     ,sir,ma,an
// }

// export default f;



const e={
    Nidhi,
    Vaibhav,
    Athashree,
    Pravin,
    Raunak,
    Anirudha,
    Shreenath,
    Shraddha,
    Aditi,
    Alok,
    AMOL,
    Ayush,
    Bhagyashri,
    Pranjal,
    PranjalJ,
    Ruie,
    Shrishail,
    Vinayak,
    pratiksha,bhavika,apurv,amey,dushant
    ,jagruti,sir,ma,an,
    Tejashree,
    Saurabh,
    Kalyani,
    YUVRAJ,
    Kashyap,
    KRISHNA,
    Aditya,
    Divya,
    Yash,
    Abhay,
    Prahesh,
    DivyaJ,
    Lenyadri
}

export default e;