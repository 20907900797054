
import cafe_vjti22 from '../images/cafevjti22.jpg';
import drona from '../images/drona22.jpg';
import eshaku22 from '../images/eshaku22.jpg';
import IMS22 from '../images/IMS22.jpg';
import kitabvala from '../images/kitabvala.jpg';
import Mindi22 from '../images/Mindi22.jpg';
import mudra from '../images/mudra.jpg';
import Rangwardhan_logo from '../images/Rangwardhan_Logo.png';
import SBI22 from '../images/SBI22.jpg';
import solu22 from '../images/Solu22.jpg';
import swad22 from '../images/swad22.jpg';
import TIB from '../images/TIB.jpg';
import VJTI from "../images/VJTI.png";
import Mahesh from "../images/Mahesh.jpg"
import Ashok from "../images/Ashok.jpg"
import Arnav from "../images/Arnav.jpg"
import Yash from "../images/Yash.png"
import rangablack from "../images/rang22-23_Black.png"
import ranga_bg from "../images/Ranga_bg.jpeg"
import djbest from "../images/djbest.jpg"
import rangawhite from "../images/rang22-23_White.png"
import ranga from "../images/Ranga.png"
import lic from "../images/LIC.jpg"
import dushant from "../images/Dushant_Technical cheif officer.jpg"
import pratiksha from "../images/Pratiksha_Chief Officer Technical.jpg"
import bhavika from "../images/IMG-20211226-WA0032 - Bhavika Purav.jpg"
import apurv from "../images/IMG_20221020_201730 - APURV SONAWANE.jpg"
import amey from "../images/Picsart_22-10-20_19-46-56-806 - Amey Pashte.jpg"


const exported= {
    dushant,pratiksha,bhavika,apurv,amey,ranga,cafe_vjti22,drona,kitabvala,eshaku22,solu22,swad22,TIB,SBI22,Rangwardhan_logo,mudra,Mindi22,VJTI,IMS22,Mahesh,Ashok,Arnav,Yash,rangablack,ranga_bg,djbest,rangawhite,lic
};

export default exported