import cafe_vjti22 from '../../images/cafevjti22.jpg';
import drona from '../../images/drona22.jpg';
import eshaku22 from '../../images/eshaku22.jpg';
import IMS22 from '../../images/IMS22.jpg';
import kitabvala from '../../images/kitabvala.jpg';
import Mindi22 from '../../images/Mindi22.jpg';
import mudra from '../../images/mudra.jpg';
import Rangwardhan_logo from '../../images/Rangwardhan_Logo.png';
import SBI22 from '../../images/SBI22.jpg';
import solu22 from '../../images/Solu22.jpg';
import swad22 from '../../images/swad22.jpg';
import TIB from '../../images/TIB.jpg';
import VJTI from "../../images/VJTI.png";
import dushant from "../../images/Dushant_Technical cheif officer.jpg"
import pratiksha from "../../images/Pratiksha_Chief Officer Technical.jpg"
import bhavika from "../../images/IMG-20211226-WA0032 - Bhavika Purav.jpg"
import apurv from "../../images/IMG_20221020_201730 - APURV SONAWANE.jpg"
import amey from "../../images/Picsart_22-10-20_19-46-56-806 - Amey Pashte.jpg"
import orient from "../../images/Orient.jpg"
import lic from "../../images/LIC.jpg"
import zoot from "../../images/Zoot.jpg"
import mt from "../../images/Maharashtra Times.jpg"
import sakal from "../../images/Sakal.jpg"
import box8 from "../../images/Box8.jpg"
import samana from "../../images/Samana.jpg"
import mayboli from "../../images/Mayaboli.jpg"
import masti from "../../images/Masti.jpg"
import sharechat from "../../images/Sharechat.jpg"
import Misal from "../../images/Misal.jpg"
import SBI from "../../images/SBI.jpg"
import Metro from "../../images/Metro.jpg"
import tss from "../../images/The Souled Store.jpg"
import ocean from "../../images/Ocean.jpg"
import EsselWorld from "../../images/EsselWorld.jpg"
import meru from "../../images/Meru.jpg"
import Yodha from "../../images/Yodha.jpg"
import Shivgarjana from "../../images/Shivgarjana.jpg"
import Karzybee from "../../images/Karzybee.jpg"
import Marketcity from "../../images/Marketcity.jpg"
import Hindmata from "../../images/Hindmata.jpg"
import Time from "../../images/Time.jpg"
import Canon from "../../images/Canon.jpg"
import Knotty from "../../images/Knotty.jpg"
import Yocket from "../../images/Yocket.jpg"
import YGP from "../../images/YGP.jpg"
import Solution from "../../images/Solution.jpg"
import Frapp from "../../images/Frapp.jpg"
import Jawed from "../../images/Jawed.jpg"
import twr from "../../images/The_White_Room.jpg"




const  b={
    cafe_vjti22,
    drona,
    kitabvala,
    eshaku22,
    solu22,
    swad22,
    TIB,
    SBI22,
    Rangwardhan_logo,
    mudra,
    Mindi22,
    VJTI,
    IMS22,
    dushant,
    pratiksha,
    bhavika,
    apurv,
    amey,
    orient,
    lic,
    zoot,
    mt,
    sakal,
    box8,
    samana,
    mayboli,
    masti,
    sharechat,
    Misal,
    SBI,
    Metro,
    tss,
    ocean,
    EsselWorld,
    meru,
    Yodha,
    Shivgarjana,
    Karzybee,
    Marketcity,
    Hindmata,
    Time,
    Canon,
    Knotty,
    Yocket,
    YGP,
    Solution,
    Frapp,
    Jawed,
    twr
}

export default b;