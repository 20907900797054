import Nidhi from '../../../images/Nidhi.jpg';
import Vaibhav from '../../../images/Vaibhav.jpg';
import Athashree from '../../../images/Athashree.jpg';
import Pravin from '../../../images/Pravin.jpg';
import Raunak from '../../../images/Raunak.jpg';
import Anirudha from '../../../images/Anirudha.jpg';
import Shreenath from '../../../images/Shreenath.jpg';
import Aditi from '../../../images/Aditi.jpg';
import Alok from '../../../images/Alok.jpg';
import AMOL from '../../../images/AMOL.jpg';
import Ayush from '../../../images/Ayush.jpg';
import Dhanashree from '../../../images/Dhanashree.jpg';
import Shraddha from '../../../images/Shraddha.jpg';
import jagruti from '../../../images/jagruti.jpg'
import DhanashriM from '../../../images/DhanashriM.jpg'
import Shubham from '../../../images/Shubham.jpg'
import Sarthak from '../../../images/Sarthak.jpg'
import Prasanna from '../../../images/Prasanna.jpeg'
import Achyut from '../../../images/Achyut.jpg'
import Pranoti from '../../../images/Pranoti.jpg'
import Soham from '../../../images/Soham.jpg'
import Sakshi from '../../../images/Sakshi.jpg'
import Devank from '../../../images/Devank.jpg'
import Komal from '../../../images/komal.jpg'
import Hinal from '../../../images/Hinal.png'
import Atul from '../../../images/Atul.jpg'




const e={
    Nidhi,
    Vaibhav,
    Athashree,
    Pravin,
    Raunak,
    Anirudha,
    Shreenath,
    DhanashriM,
    Shraddha,
    Aditi,
    Alok,
    AMOL,
    Ayush,
    Dhanashree,
    Shubham
    ,jagruti,
    Sarthak,
    Prasanna,
    Achyut,
    Pranoti,
    Sakshi,
    Devank,
    Komal,
    Atul,
    Soham,
    Hinal

}

export default e;