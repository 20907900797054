import inaug from "../../images/inaug.jpg";
import nrutya from "../../images/nrutya.jpg";
import ekpatri from "../../images/ekpatri.jpg";
import talk from "../../images/talk_show.jpg";
import pathanatya from "../../images/pathanatya.jpg";
import marathic from "../../images/marathic.jpg";
import kavadsa from "../../images/kavadsa.jpg";
import xyz from "../../images/xyz.jpg";
import launch from "../../images/launch.jpg";
import social from "../../images/socialdrive.png";
import aplakatta from "../../images/aplakatta.jpg";
import shivjayanti from "../../images/shivjaynti.jpg";
import treasure from "../../images/tresure_hunt.png";
import subhodhbhave from "../../images/Subodh bave.jpg";
import shivani from "../../images/Shivani Baokar.jpg";
import rajeeva from "../../images/Rajeeva Karandikar.jpg";
import yashvant from "../../images/Yashvant_Kanetkar.jpg";
import sush from "../../images/sushant_ghadge.jpg";
import dnyanadakadam from "../../images/dnyanadakadam.jpg";
import sheetal from "../../images/Sheetal mahajan.jpg";
import veena from "../../images/Veena Patil.jpg";
import kavita from "../../images/Kavita lad.jpg";
import supriya from "../../images/supriya_sule.jpg";
import snehal from "../../images/snehal_pradhan.jpg";
import ankita from "../../images/ankita.jpg";
import jayant from "../../images/jayant_naralikar.jpg";
import ramdan from "../../images/Ramdan Padhye.jpg";
import dr from "../../images/Dr._Abhay_Bang_.jpg";
import sharad from "../../images/Sharad Tandle.jpg";
import rajesh from "../../images/Rajesh Patil.jpg";
import raj from "../../images/Rajendra Bharud.jpg";
import ash from "../../images/Ashutosh Patki.jpg";
import palakhi from "../../images/palakhi.jpg";
import tushar from "../../images/tushargandhi.jpg";
import dilip from "../../images/dilip.jpg";
import shripal from "../../images/shripal.jpg";
import mestry from "../../images/mestry.jpg";
import pranay from "../../images/pranay.jpg";
import kashir from "../../images/kashir.jpg";
import saurabhkarde from "../../images/saurabhkarde.jpg";
import rajbhasha from "../../images/rajbhasha.jpg";
import ka from "../../images/kavita.jpg";
import hastashar from "../../images/hastashar.jpg";
import swashir from "../../images/swashar.jpeg";
import marathi from "../../images/marathi day.jpeg";
import game from '../../images/game.jpg'
import quiz from "../../images/quiz.jpg"
import rajmata from "../../images/rajmata.jpg"
import may from "../../images/may.jpg"
import aajadi from "../../images/aajadi.jpg"
import shubharambh from "../../images/shubharambh.jpg"
import standup from "../../images/standup.jpg"
import skit from "../../images/skit.JPG"
import squid from "../../images/squid.JPG"
import abhangmaifil from "../../images/abhangmaifil.JPG"
import nrityavishkar from "../../images/nritya.JPG"
import sing from "../../images/sing.JPG"
import bakshis from "../../images/bakshis.JPG"
import sparshband from "../../images/djbest.jpg"
import raj_tha from "../../images/raj.jpg"
import mahesh from "../../images/Mahesh.jpeg"
import hruta from "../../images/hruta.jpg"
import sayaji from "../../images/sayaji.jpg"
import medha from "../../images/medha.jpg"
import Jaywant from "../../images/Jaywant.jpeg"
import savani from "../../images/savani.jpeg"
import Anant from "../../images/anant.jpg"



const export1={
  shubharambh,
  standup,
  aajadi,
  may,
  rajmata,
  game,
  quiz,
  rajbhasha,ka,hastashar,swashir,marathi,
  saurabhkarde,kashir,
  mestry,
  pranay,
  shripal,
  dilip,
  tushar,
  palakhi,
  inaug,
  nrutya,
  ekpatri,
  talk,
  pathanatya,
  marathic,
  kavadsa,
  xyz,
  launch,
  social,
  aplakatta,
  shivjayanti,
  treasure,
  subhodhbhave,
  shivani,
  rajeeva,
  yashvant,
  sush,
  dnyanadakadam,
  sheetal,
  veena,
  kavita,
  supriya,
  snehal,
  ankita,
  jayant,
  ramdan,
  dr,
  sharad,
  rajesh,
  raj,
  ash,
  skit,
  squid,
  abhangmaifil,
  nrityavishkar,
  sing,
  bakshis,
  sparshband,
  raj_tha,
  Anant,
  mahesh,
  Jaywant,
  hruta,
  medha,
  savani,
  sayaji
};
export default export1;